<template>
  <div class="lpfxlist-style">
    <div class="outbox">
      <el-card class="box-card">
        <van-tabs
          title-active-color="#323232"
          title-inactive-color="#909090"
          v-model="active"
          animated
          color="#323232"
          :line-width="80"
        >
          <van-tab :title="$t('text.t313')">
            <div class="outb2">
              <div class="box2 between-center">
                <div class="box2-item">{{$t('text.t65')}}</div>
                <div class="box2-item">{{$t('text.t258')}}</div>
                <div class="box2-item">{{$t('text.t312')}}</div>
              </div>
              <div>
                <van-list
                  v-model="loading"
                  :finished="finished"
                  :finished-text="$t('text.t39')"
                  :offset="10"
                  @load="getlist"
                >
                  <div
                    class="box3 between-center"
                    v-for="item in list"
                    :key="item.id"
                  >
                    <div class="box3-item">{{item.level_address.slice(0,6)}}...{{item.level_address.slice(item.level_address.length-4)}}</div>
                    <div class="box3-item">{{item.lp_num}} LP</div>
                    <div class="box3-item">{{item.omt_num}} OMT</div>
                  </div>
                </van-list>
              </div>
            </div>
          </van-tab>
          <van-tab :title="$t('text.t314')">
            <div class="outb2">
              <div class="box2 between-center">
                <div class="box2-item">{{$t('text.t65')}}</div>
                <div class="box2-item">{{$t('text.t258')}}</div>
                <div class="box2-item">{{$t('text.t312')}}</div>
              </div>
              <div>
                <van-list
                  v-model="loading1"
                  :finished="finished1"
                  :finished-text="$t('text.t39')"
                  :offset="10"
                  @load="getlist1"
                >
                <div
                    class="box3 between-center"
                    v-for="item in list1"
                    :key="item.id"
                  >
                    <div class="box3-item">{{item.level_address.slice(0,6)}}...{{item.level_address.slice(item.level_address.length-4)}}</div>
                    <div class="box3-item">{{item.lp_num}} LP</div>
                    <div class="box3-item">{{item.omt_num}} OMT</div>
                  </div>
                </van-list>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      active: 0,
      limit: 20,

      list: [],
      loading: false,
      finished: false,
      page: 1,

      list1: [],
      loading1: false,
      finished1: false,
      page1: 1,
    };
  },
  computed: {},
  created(){},
  mounted() {},
  methods: {
    getlist() {
      const user_address = this.$local.get('shop:account');
      let arg = {
        page: this.page,
        limit: this.limit,
        address: user_address,
        level:1
      };
      this.$http.post("/omt_pledge/shareReleaseList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
    getlist1() {
      const user_address = this.$local.get('shop:account');
      let arg = {
        page: this.page1,
        limit: this.limit,
        address: user_address,
        level:2
      };
      this.$http.post("/omt_pledge/shareReleaseList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished1 = true;
        this.page1++;
        this.loading1 = false;
        this.list1 = [...this.list1, ...data];
      });
    },
  },
};
</script>

<style lang="less">
.lpfxlist-style {
  .outbox {
    padding: 20px 0;
    .box-card {
      width: 350px;
      border-radius: 20px;
      margin: auto;
      min-height: 550px;
      .box1 {
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 25px;
        text-align: center;
      }
      .outb2 {
        padding-top: 20px;
        .outb2-card {
          width: 294px;
          border-radius: 10px;
          margin: 0 auto 25px;
          box-sizing: border-box;
          font-weight: bold;
          color: #323232;
          .outb2-card-b1 {
            margin-bottom: 20px;
            font-size: 15px;
          }
          .outb2-card-b2 {
            font-size: 11px;
            margin-bottom: 15px;
            div:first-of-type{
              max-width: 70%;
            }
          }
          .outb2-card-b2:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .box2 {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        margin-bottom: 5px;
        .box2-item {
          width: 33.3%;
        }
      }
      .box3 {
        font-size: 9px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        padding: 16px 0;
        border-bottom: 1px solid #e1e1e1;
        .box3-item {
          width: 33.3%;
          .box3-item-i1 {
            width: 8px;
            height: 8px;
            margin-left: 4px;
          }
        }
      }
    }
  }
  .ppbox {
    width: 218px;
    padding: 20px 0 4px;
    .ppbox-t1 {
      font-size: 10px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 22px;
      text-align: center;
    }
    .ppbox-b1 {
      font-size: 9px;
      font-weight: 500;
      color: #323232;
      text-align: center;
      flex-wrap: wrap;
      .ppbox-b1-item {
        width: 50%;
        margin-bottom: 20px;
      }
    }
  }
}
</style>